import {Swiper, SwiperSlide} from "swiper/react";
import './TimePicker.scss'
import {useEffect, useState} from "react";
import hand_icon from  "../../../public/images/hand-pointer-icon.svg"
import line_icon from  "../../../public/images/line-icon.svg"

const initialWeight = Array.from(Array(151).keys()).slice(40);
const initialWeightDecimal = Array.from({length: 10}, (_, index) => (index ) / 10);


const TimePicker = ({handleChange, parentWeight, showTitle=true}) => {
    const [weight, setWeight] = useState(0)
    const [fixed, setFixed] = useState(0)
    const [decimal, setDecimal] = useState(0)
    const [firstSwiper, setFirstSwiper] = useState(null)
    const [secondSwiper, setSecondSwiper] = useState(null)

    useEffect(function (){
        if (parentWeight != weight){
        slideToSecond(getDecimalPart(parentWeight))
        slideTo(parseInt(parentWeight - 38))
        handleChange('weight', parseFloat(parentWeight))
        }
    }, [parentWeight])

    const changeWeight = (name, number) =>{
        if (name=='decimal'){
            setDecimal(parseFloat(number.toFixed(1)))
            let num = (fixed + number).toFixed(1)
            setWeight(parseFloat(num))
            handleChange('weight', parseFloat(num))
        }
        if (name=='fixed'){
            setFixed(parseInt(number))

            let num = (number + decimal).toFixed(1)
            setWeight(parseFloat(num))
            handleChange('weight', parseFloat(num))
        }
    }

    const getDecimalPart = (num) =>{
        if (Number.isInteger(num)) {
            return 0;
        }

        const decimalStr = num.toString().split('.')[1];
        return Number(decimalStr);
    }

    const slideTo = (index) => {
        if(firstSwiper)
            firstSwiper.slideTo(index)
    };

    const slideToSecond = (index) => {
        if(secondSwiper)
            secondSwiper.slideTo(index)
    };

    return (
        <div>
            <div className={'my-4 flex flex-col'}>
               <div className={'mb-5'}> {showTitle && 'وزنت رو مشخص کن تا بگیم روزانه چند لیوان آب بنوشی'} </div>
                <div>تعیین وزن</div>
            </div>

            <div className="timePicker box-der">
                <div className={''}>
                    <img src={hand_icon} alt=""/></div>
                <div className={'ml-2 text-lg'}>
                    <img className={'line-icon'} src={line_icon} alt=""/></div>
                <div className={'ml-3 text-lg'}>Kg</div>
                <div className="picker">
                    <Swiper
                        onSwiper={setSecondSwiper}
                        initialSlide={2}
                        slidesPerView={3}
                        direction="vertical"
                        centeredSlides
                        grabCursor
                        loop
                        onSlideChange={(e) => changeWeight('decimal', e.realIndex * 0.1 )}
                    >
                        {initialWeightDecimal.map((decimal) => {
                            return (
                                <SwiperSlide key={decimal}>
                                    <div className="time" style={{direction: 'ltr'}}>{("" + decimal).padStart(2, "0").substring(1)}</div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                <div className="picker">
                    <Swiper
                        onSwiper={setFirstSwiper}
                        // initialSlide={30}
                        slidesPerView={3}
                        direction="vertical"
                        centeredSlides
                        grabCursor
                        loop
                        onSlideChange={(e) => changeWeight('fixed', e.realIndex + 40)}

                    >
                        {initialWeight.map((w) => {
                            return (
                                <SwiperSlide key={w}>
                                    <div className="time">{("" + w).padStart(2, "0")}</div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default TimePicker;
