import * as React from 'react';
import {
    Unstable_NumberInput as BaseNumberInput
} from '@mui/base/Unstable_NumberInput';
import {styled} from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {InputAdornment} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import './GoalDetermination.scss';

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
        <BaseNumberInput
            slots={{
                root: StyledInputRoot,
                input: StyledInput,
                incrementButton: StyledButton,
                decrementButton: StyledButton,
            }}
            slotProps={{
                incrementButton: {
                    children:
                        <AddIcon className="text-p-green " style={{fontSize: '2rem'}} fontSize="small"/>,
                    className: 'increment',
                },
                decrementButton: {
                    children:
                        <RemoveIcon className="text-p-green" style={{fontSize: '2rem'}} fontSize="small"/>,
                    className: 'decrement',
                },
            }}
            {...props}
            ref={ref}
        />
    );
});


export default function GoalDetermination({handleChange, weight, water, goal, className}) {
    const [value, setValue] = useState(1);
    const localstorageWeight = localStorage.getItem('weight')
    const realGoal = Math.floor((localstorageWeight * 0.033) * 1000)
    const [isGoal, setIsGoal] = useState(1)
    useEffect(function () {
        console.log('goal, water',goal, water, weight, localstorageWeight)
        if (goal &&  weight ==localstorageWeight && isGoal && water==realGoal) {
            setValue(Math.floor(goal / 250))
            setIsGoal(0)
            console.log('goal, waterr',goal, water, weight, localstorageWeight)


        } else {
            setValue(Math.floor(water / 250))

        }
    }, [water])

    const changeValue = (val) => {
        setValue(val)
        handleChange('water', val * 250)
    }
    return (
        <div className={`${className} glass-determination`}>
            <div className={'my-4'}>تنظیم هدف دلخواه برای نوشیدن آب:</div>
            <NumberInput value={value}
                         onChange={(event, val) => changeValue(val)}
                         startAdornment={<InputAdornment>
                             <span className="text-xl font-['iransans']" style={{
                                 color: '#404040',
                                 transform: 'translate(20px, 0px)'
                             }}>لیوان</span>
                         </InputAdornment>} className="bg-white px-8 py-4 rounded-xl box-der" dir="ltr" aria-label="Quantity Input"
                         min={1} max={99}/>
        </div>
    );

}


const StyledInputRoot = styled('div')(
    ({theme}) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`,
);

const StyledInput = styled('input')(
    ({theme}) => `
  font-size: 1.25rem;
  font-family: iransans;
  // font-weight: 400;
  line-height: 2rem;
  color: #404040;
  transform: translate(20px, 0px);
  // background: initial;
  border-radius: 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  &:hover {
  }
  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledButton = styled('button')(
    ({theme}) => `
  font-family: 'iransans';
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 13px;
  background: #fff;
  width: 50px;
  height: 45px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
    border-color: #404040;
        margin-left:auto
  }
    &.decrement {
    border-color: #404040;
    margin-right:auto
  }
`,
);