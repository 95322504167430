import {useEffect, useState} from "react";
import HttpService from "../../services/HttpService";
import MessageHandle from "../../services/MessageHandle";
import './Splash.scss';
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";
import Lottie from "lottie-react";
import glass from '../../animations/glass.json';
import errorAnimation from '../../animations/error.json';
import './Splash.scss';
import {
    Button,
    LinearProgress,
    linearProgressClasses
} from "@mui/material";
import {styled} from "@mui/system";

const Splash = () => {
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [hasError, setHasError] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    useEffect(() => {
        sync()
    }, []);

    const sync = () => {
        setHasError(false);

        const token = searchParams.get("token") ?? ''
        const ssoPayload = searchParams.get("sso-payload") ?? ''

        if (!token && !ssoPayload) {
            setTimeout(() => {
                setHasError(true);
            }, 2500);

            return false
        }

        HttpService.get('splash', {
            'token': token,
            'sso-payload': ssoPayload
        }).then(res => {
            let weight;
            let water;
            setData(res.data.data);
            if (res.data.data.user_goal) {
                weight = res.data.data.user_goal.weight;
                water = res.data.data.user_goal.water_value;
                localStorage.setItem('goal', water)
                localStorage.setItem('weight', weight)

            } else {
                weight = res.data.data.init.weight.init;
                localStorage.setItem('weight', weight)
                localStorage.setItem('goal', null)

            }
            localStorage.setItem('token', res.data.data.access_token)
            localStorage.setItem('water_intake', res.data.data.water_intake)
            document.location.replace('/main');
        }).catch(error => {
            MessageHandle.error(error)
        })
    }

    const render = () => {
        return (
            <div className={`splash-component ${hasError ? 'has-error' : ''}`}>
                <div className="splash-loader">
                    <Lottie animationData={glass} loop={true}/>
                    <BorderLinearProgress className="mt-4"/>
                </div>
                <div className={"splash-error"}>
                    <div>
                        <Lottie animationData={errorAnimation} loop={true}/>
                        <p>متاسفانه خطایی رخ داده است.</p>
                        <Button variant="contained" onClick={sync}>تلاش مجدد</Button>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            {render()}
        </div>
    )
}
export default Splash;
