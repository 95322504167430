import waterImage from '../../../public/icons/water.svg'
import './WaterNeeds.scss';

const WaterNeeds = ({weight, className}) => {
    return (
        <div className={`${className} `}>
            <div className={'my-4'}>آب مورد نیاز بدن شما:</div>

            <div className="bg-white water-needs-component flex p-5 rounded-xl justify-between text-[#404040] items-baseline box-der">
                <div>
                    <img src={waterImage} alt="" className="inline align-bottom"/>
                    <span className="text-p-blue font-bold mx-2">{Math.floor((weight * 0.033) * 1000)}</span>
                    <span className="font-light">میلی لیتر</span>
                </div>
                <div>معادل</div>
                <div>
                    <span className="text-p-blue font-bold">{Math.floor(((weight * 0.033) * 1000) / 250)} لیوان</span>
                    <span className="font-light pr-1">(250 میلی‌لیتری)</span>
                </div>
            </div>
        </div>

    );
};

export default WaterNeeds;
