import HeaderContent
    from "../../components/headerContent/HeaderContent";
import BannerGoalPage
    from "../../components/bannerGoalPage/BannerGoalPage";
import TimePicker
    from "../../components/timePicker/TimePicker";
import WaterNeeds
    from "../../components/waterNeeds/WaterNeeds";
import GoalDetermination
    from "../../components/goalDetermination/GoalDetermination";
import {useEffect, useState} from "react";
import HttpService from "../../services/HttpService";
import SimpleBackdrop
    from "../../components/backdrop/SimpleBackdrop";
import LoadingButton from '@mui/lab/LoadingButton';

import './Goal.scss';

const Goal = () => {
    const [isWeight] = useState(localStorage.getItem('goal')!='null');
    const [loading, setLoading] = useState(false);
    const [backdrop, setBackdrop] = useState('open');
    const [formData, setFormData] = useState({water: 250, weight: 70, goal: null});
    const [saving, setSaving] = useState(false);
console.log(isWeight)
    useEffect(() => {
        sync()
    }, []);

    const handleChange = (name, value) => {
        console.log(name,value)
        if (name == 'weight') {
            let glassValue = Math.floor((value * 0.033) * 1000)
            setFormData((prevFormData) => ({
                ...prevFormData,
                ['water']: glassValue
            }));
            // if (formData.goal != null && formData.water != formData.goal)
            //     setFormData((prevFormData) => ({
            //         ...prevFormData,
            //         ['goal']: null
            //     }));
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));

    };

    const handleChangeGoal = (name, value) => {
        console.log('handleChangeGoal',name,value)
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            ['goal']: null
        }));
    };
    const handleChangeInitial = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));

    };

    const sync = () => {
        setBackdrop('close')
        const waterIntakeItem = localStorage.getItem('water_intake');
        const weightItem = localStorage.getItem('weight');
        const goalItem = localStorage.getItem('goal');
        console.log(goalItem)
        setLoading(true)
        handleChangeInitial('goal', parseFloat(goalItem))
        handleChangeInitial('weight', parseFloat(weightItem))
        handleChangeInitial('water', parseFloat(waterIntakeItem))
    }

    const setGoal = () => {
        if (saving) {
            return false;
        }
        setSaving(true);
        HttpService.post('user-goals', {weight: formData.weight, water_value: formData.water}).then(res => {
            localStorage.setItem('goal', res.data.data.user_goal.water_value)
            localStorage.setItem('weight', res.data.data.user_goal.weight)
            setSaving(false);
            document.location.replace('/main');
        }).catch(error => {
            // MessageHandle.error(error)
            setSaving(false)
        })
    }

    const render = () => {
        return (
            <div className={'goal-component m-4'}>
                <HeaderContent
                title={isWeight? 'هدف روزانه' : 'آب شمار'}
                />
                {!isWeight && <BannerGoalPage/>}
                <TimePicker
                    handleChange={handleChange}
                    parentWeight={formData.weight}
                    showTitle={!isWeight?true:false}
                />
                <WaterNeeds
                    weight={formData.weight}
                    className={isWeight && 'mt-8'}
                />
                <GoalDetermination
                    handleChange={handleChangeGoal}
                    water={formData.water}
                    goal={formData.goal}
                    weight={formData.weight}
                    className={isWeight && 'mt-8'}
                />
            <div className={'w-full text-center'}>
                <LoadingButton onClick={setGoal}
                               loading={saving}
                               variant="contained"
                               className={isWeight? 'btn-submit-goal' : 'btn-submit'}>
                    {isWeight? 'ثبت': 'ثبت و ادامه'}
                </LoadingButton>
            </div>
            </div>
        );
    };
    return (
        <div>
            {loading?render():''}
            {<SimpleBackdrop handle={backdrop}/>}
        </div>
    )
}
export default Goal;
