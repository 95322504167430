import './HeaderContent.scss';
import {useNavigate} from "react-router-dom";
import backIcon from "/public/icons/back-icon.svg"

const HeaderContent = (props) => {
    const {title} = props
    const navigate = useNavigate();

    return <></>;

    return (
            <div className="header">
                <div  className={'py-2  relative'}>
                     <div className="action-bar-button">
                        <img onClick={() => action('goBack')} src={backIcon}/>
                    </div>
                    <b className="header-title">{title}</b>
                </div>
            </div>
    );

    function action(target = null) {
        if (target == 'goBack') {
            navigate(-1);
        }
    }
}
export default HeaderContent;
