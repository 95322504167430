import axios from "axios";
import AuthService from "./AuthService";
const APP_URL = process.env.APP_URL;

class HttpService {
    static get(url, data, headers=null) {
        url = APP_URL + url
        if (data){
            let query = new URLSearchParams(data).toString()
            url += '?'+query
        }
        let config = {
            headers: {}
        }
        if (headers){
            Object.keys(headers).forEach(key => {
                config.headers[key] = headers[key]
            });
        }
        const token = AuthService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
            return axios.get(url, config)
        } else {
            return axios.get(url, config)
        }
    }

    static post(url, data, headers = null) {
        url = APP_URL + url
        let config = {
            headers: {}
        }
        if (headers){
            Object.keys(headers).forEach(key => {
                config.headers[key] = headers[key]
            });
        }
        const token = AuthService.getUser();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
            return axios.post(url, data, config)
        } else {
            return axios.post(url, data, config)
        }
    }

    static delete(url, data, headers = null) {
        url = APP_URL + url
        let config = {
            headers: {}
        }
        if (headers){
            Object.keys(headers).forEach(key => {
                config.headers[key] = headers[key]
            });
        }
        const token = AuthService.getUser();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
            return axios.delete(url, config)
        } else {
            return axios.post(url, config)
        }
    }

   static getProviderId(){
        const query = new URLSearchParams(window.location.search);
        const provider_token = query.get('provider_token');
        const storage_provider_token = localStorage.getItem('provider_token')
        if (provider_token){
            localStorage.setItem('provider_token', provider_token)
            return provider_token;
        }else if (storage_provider_token){
            return storage_provider_token
        }
        return null;
    }
}

export default HttpService;
