import React, {useEffect, useState} from 'react';
import Chart from "react-apexcharts";
import glass from '../../../public/icons/glass.svg'
import './MyCharts.scss';

const MyCharts = (props) => {
    const {goal} = props
    let {changed} = props

    let {drink} = props
    let {percent} = props

    if (percent > 100) {
        percent = 100;
    }

    let options = {
        chart: {
            animations: {
                enabled: true,
                dynamicAnimation: {
                    speed: 800,
                }
            },
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: ['80%'],
                },
                track: {
                    background: ["#f1faff", '#f1faff'],
                },
                startAngle: -150,
                endAngle: 150,
                dataLabels: {
                    name: {
                        fontWeight: 300,
                        color: '#777777',
                        offsetY: 30,
                        fontFamily: 'iransans'
                    },
                    value: {
                        fontWeight: 900,
                        offsetY: -20,
                        fontSize: '22px',
                        fontFamily: 'iransans',
                        color: '#777777',
                        formatter: function (val) {
                            return '';
                        }
                    }
                }
            }
        },
        fill: {
            opacity: 1,
        },
        colors: [
            percent >0?"#48AAD8":"#f1faff",
        ],
        stroke: {
            lineCap: 'round',
            dashArray: [0, 14]
        },
        labels: [''],
    };

    return (
        <div className="chart-container">
            <div className="sum">
                <div className="counter">
                    <div className={`drinks-count ${changed ? 'changed' : ''}`}>{Math.floor(drink)}</div>
                    <div className="separator">/</div>
                    <div className="goal">{Math.floor(goal)}</div>
                </div>

                <div className="description">لیوان آب نوشیده شده</div>
            </div>

            <div className={`chart-image ${changed ? 'changed' : ''}`}>
                <img src={glass}/>
            </div>

            <Chart
                options={options}
                series={[percent]}
                type="radialBar"
                height="400"
            />
        </div>
    )
}

export default MyCharts;
