import React from 'react';
import './styles/app.css'
import  './index.css'
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import Master from "./layouts/master/Master";
import Goal from "./pages/goal/Goal";
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import Splash from "./pages/splash/Splash";
import Main from "./pages/main/Main";

const theme = createTheme({
    palette: {
        primary: {
            main: '#0EAD98',
            contrastText: '#fff',
        },
        secondary: {
            main: '#E0C2FF',
            light: '#F5EBFF',
            contrastText: '#47008F',
        },
    },
});


const router = [
    <ThemeProvider theme={theme}>
        <BrowserRouter key={'main'}>
            <Routes>
                <Route element={<Master/>}>
                    <Route path="/" element={<Splash/>}/>
                    <Route path="/main" element={<Main/>}/>
                    <Route path="/goal" element={<Goal/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
];


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    router
);
