class AuthService {
    static logout() {
        localStorage.clear();
        localStorage.removeItem("error");
        localStorage.removeItem("user");
        localStorage.removeItem('auth');
    }

    static getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    static getAccessToken() {
        const user = this.getCurrentUser();

        if (user && user.access_token) {
            return user.access_token;
        }

        return null;
    }

    static getUser() {
        return localStorage.getItem('token');
    }

    static successAuth(data) {
        localStorage.setItem("user", JSON.stringify(data));
    }

    static check() {

    }

}

export default AuthService;
