import {Outlet} from "react-router-dom";
import Box from '@mui/material/Box';
import './Master.scss'
import {ToastContainer} from "react-toastify";

function Master() {
    return (
        <>
            <div className="lg:max-w-lg lg:mx-auto">
                    <Box className="">
                        <Outlet/>
                    </Box>
            </div>
                    <ToastContainer toastStyle={{
                        textAlign: 'right'
                    }}/>
        </>
    );
}

export default Master;