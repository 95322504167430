import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {Button} from "@mui/material";
import './DailyWaterDrinking.scss';

const DailyWaterDrinking = ({addGlass, removeGlass}) => {
    return (
        <div className="flex justify-around m-5 mt-9 daily-water-drinking">
            <Button className="btn-drink" onClick={(e) => addGlass(250)}>
                <AddIcon className="text-p-green" style={{fontSize: '2rem'}}
                         fontSize="small"/>
            </Button>
            <div className="description">
                <div className="text-p-gree">یک لیوان</div>
                <div className="" style={{color: '#777777'}}>
                    <span className={'pr-2'}>250</span>
                    <span>ml</span>
                </div>
            </div>
            <Button className="btn-drink" onClick={(e) => removeGlass(250)}>
                <RemoveIcon className="text-p-green" style={{fontSize: '2rem'}} fontSize="small"/>
            </Button>
        </div>
    );

}
export default DailyWaterDrinking;