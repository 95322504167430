import HeaderContent
    from "../../components/headerContent/HeaderContent";
import DailyWaterDrinking
    from "../../components/dailyWaterDrinking/DailyWaterDrinking";
import MyCharts from "../../components/myCharts/MyCharts";
import setting from "../../../public/images/setting.svg";
import {useEffect, useState} from "react";
import SimpleBackdrop
    from "../../components/backdrop/SimpleBackdrop";
import HttpService from "../../services/HttpService";
import Goal from "../goal/Goal";
import {Link, useNavigate} from "react-router-dom";

const Main = (props) => {
    try {
        if (!Math.floor(localStorage.getItem('goal')) || !parseInt(localStorage.getItem('weight'))) {
            return <Goal/>
        }
    } catch (e) {
        return <Goal/>
    }

    const [backdrop, setBackdrop] = useState('open');
    const [loading, setLoading] = useState(true);
    const [goalCurrent, setGoalCurrent] = useState(null);
    const [drinkCurrent, setDrinkCurrent] = useState(null);
    const [percentCurrent, setPercentCurrent] = useState(null);
    const [chartTransition, setChartTransition] = useState(false);

    const navigate = useNavigate();

    useEffect(function () {
        sync()
        setLoading(false)
        setBackdrop('close')
    }, [])

    const sync = () => {
        setChartTransition(false);

        const water_intake = localStorage.getItem('water_intake')
        const currentGoal = Math.floor(localStorage.getItem('goal') / 250)
        const waterIntake = water_intake>0?Math.floor(localStorage.getItem('water_intake') / 250):0
        const percentage = (waterIntake / currentGoal) * 100
        setGoalCurrent(currentGoal);
        setDrinkCurrent(waterIntake);
        setPercentCurrent(percentage);
        setChartTransition(true);

        setTimeout(() => {
            setChartTransition(false);
        }, 1000)
    }

    const addGlass = (value) => {
        HttpService.post('user-water-logs', {water_value: value}).then(res => {
            localStorage.setItem('water_intake', parseInt(localStorage.getItem('water_intake')) + value);
            sync();
        }).catch(error => {
            // MessageHandle.error(error)
        })
    }

    const removeGlass = (value) => {
        HttpService.delete('user-water-logs', {water_value: value * -1},).then(res => {
            localStorage.setItem('water_intake', parseInt(localStorage.getItem('water_intake')) - value)
            sync()
        }).catch(error => {
        })
    }

    const getChart = (value) => {
        return <MyCharts
            goal={goalCurrent}
            drink={[value]}
            percent={percentCurrent}
            changed={chartTransition}
        />;
    }

    const render = () => {
        return (
            <div className={'m-4'}>
                <HeaderContent
                    title={'آب شمار'}
                />
                <div className={'flex justify-end '} onClick={e => navigate('/goal')}>
                    <img src={setting} alt=""  className="cursor-pointer"
                         style={{animation: "spin 4s infinite linear"}} />
                </div>
                <div className="my-4 text-center ">امروز چند لیوان آب نوشیدی؟</div>
                {getChart(drinkCurrent)}
                <DailyWaterDrinking
                    addGlass={addGlass}
                    removeGlass={removeGlass}
                />
            </div>
        );
    };

    return (
        <>
            {goalCurrent && drinkCurrent >= 0 && percentCurrent >= 0 ? render() : loading}
            <SimpleBackdrop handle={backdrop}/>
        </>
    )
}
export default Main;
