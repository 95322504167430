import AuthService from "./AuthService";
import {toast} from 'react-toastify';
import 'react-toastify/scss/main.scss'

class MessageHandle {
    static error(errorResponse) {
        const config = {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        }
        if (errorResponse.response && errorResponse.response.status === 422) {
            const errors = errorResponse.response.data.errors;
            const key = Object.keys(errors)[0]
            toast.error(errors[key][0], config);
        } else if (errorResponse.response && errorResponse.response.status === 401) {
            toast.error(errorResponse.response.data.message, config);
            AuthService.logout();
            window.location.replace('/')
        } else if (errorResponse.response && errorResponse.response.status) {
            toast.error(errorResponse.response.data.message, config);
        } else if (typeof errorResponse === 'string') {
            toast.error(errorResponse, config);
        } else if (errorResponse.message) {
            toast.error(errorResponse.message, config);
        }
    }

    static success(message) {
        toast.success(message,
            {
                theme: 'colored',
                className: 'mt-4 right-2 w-full max-w-xs',
            }
        );
    }

}


export default MessageHandle;