import banner from "/public/images/banner-goal.svg"

const BannerGoalPage = () => {
    return (
            <div className="">
                <img className={'block w-full'}  src={banner}/>
            </div>
    );

}
export default BannerGoalPage;